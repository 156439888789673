.box-drop {
    position: absolute;
    width: max-content;
    height: max-content;
    border-radius: 5px;
    background-color: var(--bg-color);
    box-shadow: 0 0 1px 1px var(--primary);
    box-sizing: border-box;
    padding: 10px 15px;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: opacity 0.3s ease-in-out;
    cursor: move;
    z-index: 2;
    overflow: hidden;

    &.show {
        visibility: visible;
        opacity: 0.2;
    }

    &.hide {
        visibility: hidden;
        opacity: 0;
    }

    &.pinned {
        opacity: 1;
        z-index: 2;
    }

    &:hover:not(.pinned) {
        opacity: 1;
        z-index: 3;
    }
}


@media only screen and (max-width: 700px) {
    .close-btn {
        top: 5px !important;
        right: 5px !important;
    }
    .show{
        opacity: 1 !important;
        z-index: 2 !important;
    }
}