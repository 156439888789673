.audio-control {
  display: flex;
  align-items: center;

  audio {
    display: none; // Hide default audio controls
  }

  button {
    background-color: var(--secondary);
    border: 1px solid var(--primary);
    color: white;
    padding: 7px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &.play {
      &:hover {
        background-color: var(--quaternary);
      }
    }

    &.pause {
      background-color: var(--tertiary);
      
      &:hover {
        background-color: var(--quaternary);
      }
    }
  }

  .progress-bar {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: var(--primary);
    border-radius: 5px;
    border: 1px solid var(--primary);
    cursor: pointer;
    margin: 0 10px; // Adding margin for better spacing

    .progress {
      height: 100%;
      background-color: var(--secondary);
      border-radius: 5px;
      transition: width 0.1s;
    }
  }

  .volume-control {
    position: relative;
    width: 100px;
    height: 10px;
    background-color: var(--secondary);
    border-radius: 5px;
    border: 1px solid var(--primary);
    cursor: pointer;

    .volume-level {
      height: 100%;
      background-color: var(--tertiary);
      border-radius: 5px;
      transition: width 0.1s;
    }
  }
}

.audio-control {
  gap: 5px !important;
  padding: 5px 0 !important;

  button {
      padding: 5px 10px !important;
      font-size: 12px;
  }

  .progress-bar {
      height: 7px !important;
      border: 0;

      .progress {
          height: 7px !important;
      }
  }

  .volume-control {
      height: 7px !important;

      .volume-level {
          height: 7px !important;
      }
  }
}