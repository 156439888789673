.music{
    top: 0;
    left: 50px;
}

.info-container {
    width: max-content;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .banner-container {
        width: 150px;
        height: 150px;
        border-radius: 5px;
        border: 1px solid var(--primary);

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
        }
    }

    .text-container {
        width: max-content;
        display: flex;
        flex-direction: column;
        text-align: center;

        a {
            font-weight: 300;
            text-decoration: none;
            color: var(--primary);
        }

        h6 {
            color: var(--tertiary);
        }
    }

}

.audio-control {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 5;
    color: var(--tertiary);
    font-size: 15px;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    #musicContent {
        width: 200px !important;
        height: 250px !important;
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .info-container {
        width: 100% !important;
        height: fit-content;
        padding: 0;
        gap: 5px !important;

        .banner-container {
            width: 100%;
            height: 140px;
        }

        .text-container {
            a {
                font-size: 12px;
            }

            h6 {
                font-size: 10px;
            }
        }
    }


    .audio-control {
        gap: 5px;
    }
}