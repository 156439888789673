.game{
    bottom: 0;
    left: 50px;
    z-index: 3 !important;
}

.content-container {
    position: relative;
    width: 600px;
    height: 450px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: start;
    align-items: center;

    .game-score {

        h5 {
            font-weight: 300;
        }
    }
}

.end-info {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    .dark-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--secondary);
        z-index: 4;
        opacity: 0.8;
    }

    .text {
        position: relative;
        z-index: 5;
        color: var(--primary);
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 10px;

        a{
            cursor: pointer;
        }
    }
}

.pin-btn {
    position: absolute;
    top: 5px;
    left: 10px;
    z-index: 5;
    color: var(--primary);
    font-size: 15px;
    cursor: pointer;
}

.pause-btn {
    position: absolute;
    top: 5px;
    left: 40px;
    z-index: 5;
    color: var(--primary);
    font-size: 15px;
    cursor: pointer;
}


.close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 5;
    color: var(--tertiary);
    font-size: 15px;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    .game{
        left: 50%;
        transform: translateX(-50%);
        bottom: 60px;
    }
    .content-container {
        width: 300px !important;
        height: 300px !important;
        box-sizing: border-box;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .bird{
        width: 30px !important;
        height: 30px !important;
    }

    .game-score {
        h5 {
            font-size: 12px;
        }
    }

    .end-info {
        .text {
            a {
                font-size: 12px;
            }
        }
    }
}

@media only screen and (max-width: 400px) {

    
    .game{
        width: fit-content !important;
        bottom: 50px;
    }

    .content-container {
        width: 250px !important;
        height: 300px !important;
    }
}