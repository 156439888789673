#timeline {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;
    box-sizing: border-box;

    #timeline-content {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        box-shadow: 0 0 1px 1px var(--primary);
        border-radius: 5px;
        padding: 30px 50px;
        box-sizing: border-box;
        height: max-content;

        .text-container {
            margin: auto;
            width: 70%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            text-align: center;
            margin-bottom: 30px;

            h1 {
                color: var(--tertiary);
                font-weight: bold;
            }

            p {
                color: var(--primary);
                font-weight: 300;
            }
        }

        #timeline-information {
            width: 100%;
            display: flex;
            justify-content: start;
            cursor: move;
            padding: 10px 15px;
            overflow-x: hidden;
            scrollbar-width: none;
            box-sizing: border-box;
            transition: all 0.05s ease-in-out;

            .moment {
                position: relative;
                top: -150px;
                left: 50px;
                opacity: 0;
                display: flex;
                align-items: start;
                flex-direction: column;
                gap: 20px;
                border: 1px solid transparent;

                .moment-info-container {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    top: 8px;
                }

                .title {
                    width: 215px;
                    position: relative;

                    h4 {
                        font-weight: 300;
                        color: var(--primary);
                        transition: all 0.3s ease-in-out;
                    }

                    &.link {
                        cursor: pointer;
                        text-decoration: underline;
                        text-decoration-color: var(--primary);
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            text-decoration-color: var(--tertiary);

                            h4 {
                                color: var(--tertiary);
                            }
                        }
                    }
                }

                .image {
                    position: relative;
                    width: 100%;
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    box-sizing: border-box;
                    border: 1px solid var(--primary);
                    border-bottom: none;
                    border-radius: 5px 5px 0px 0;
                    background-color: transparent;
                    transition: all 0.3s ease-in-out;

                    img {
                        width: 100%;
                        height: 100%;
                        align-self: flex-start;
                        object-fit: contain;
                    }
                }

                .point {
                    position: absolute;
                    width: 5px;
                    height: 20px;
                    border-radius: 5px;
                    box-shadow: 0 0 1px 1px var(--primary);
                    transition: all 0.3s ease-in-out;
                    bottom: 40px;

                }

                .date {
                    h5 {
                        font-weight: bold;
                    }

                    color: var(--primary);
                }
            }

            .crossed {
                background-color: var(--tertiary) !important;
            }

            .crossed-image {
                background-color: var(--quaternary) !important;
            }
        }

        .timeline-container {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .helper-text {
                h5 {
                    color: var(--primary);
                    font-weight: 300;
                }
            }

            .line {
                position: absolute;
                cursor: move;
                border: 0;
                width: 100%;
                top: -105px;
                height: 5px;
                border-radius: 5px;
                background-color: var(--secondary);
                margin: 20px 0;
                box-shadow: 0 0 1px 1px var(--primary);
                z-index: 2;

                .inner-line {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 5px;
                    max-width: 100% !important;
                    background-color: var(--tertiary);
                    border-radius: 5px;
                    transition: all 0.3s ease-in-out;
                    z-index: 3;
                }

                .arrow-button {
                    position: absolute;
                    font-size: 20px;
                    color: var(--primary);
                    cursor: pointer;
                    z-index: 3;

                    .arrow {
                        position: relative;
                        top: -25px;
                        left: 0;
                        transition: all 0.3s ease-in-out;
                        color: var(--tertiary);
                    }
                }

                .left-button {
                    left: 0;
                    top: 0;
                }

                .right-button {
                    right: 0;
                    top: 0;
                }
            }
        }
    }

    @keyframes moment-in {
        from {
            opacity: 0;
            left: 50px;
            top: -150px;
        }

        to {
            opacity: 1;
            left: 0;
            top: 0;
        }
    }
}


@media only screen and (max-width: 700px) {
    #timeline {
        padding: 0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        #timeline-content {
            padding: 20px;
            height: max-content;
            gap: 20px;
            display: flex;
            justify-content: start;
            align-items: center;

            .text-container {
                width: 100%;
                margin: 0;
                margin-bottom: 30px;
            }

            #timeline-information {
                padding: 0;
                overflow-x: auto;
                overflow-y: hidden;
                height: 220px;

                .moment {

                    .image {
                        height: 150px;
                    }

                    .point {
                        bottom: 45px !important;
                    }
                }
            }


        }

        .timeline-container .line {
            top: -97px;
        }
    }
}

@media only screen and (max-width: 400px) {
    #timeline {
        padding: 50px 10px 10px;


        .image {
            height: 100px !important;
        }

        .text-container {
            width: 100%;
            margin: 0;
            margin-bottom: 30px;
        }

        .line {
            top: -159px !important;
        }

        .moment-info-container {
            top: 10px !important;
        }


        #timeline-information {
            padding: 0;

            .moment {

                .image {
                    width: 160px !important;
                }

            }
        }

        #timeline-content #timeline-information .moment .point {
            bottom: 95px !important;
        }
    }
}