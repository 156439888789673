.game-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .bird {
    width: 40px;
    height: 40px;
    background-color: var(--tertiary);
    position: absolute;
    border-radius: 5px;
    transition: all 0.15s ease-in-out;
  }

  .pipe {
    position: absolute;
    border: 1px solid var(--primary);
    width: 25px;
    background-color: var(--secondary);

    &.top {
      border-top: 0;
    }

    &.bottom {
      border-bottom: 0;
    }
  }

  .game-paused {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--quaternary);
    z-index: 4;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    color: var(--primary);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    border-radius: 5px;

    h4 {
      font-weight: 400;
    }
  }
}