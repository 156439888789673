#home {
    position: relative;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;

    .main-container {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .text-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;

            h1 {
                color: var(--tertiary);
                font-weight: 800;
            }

            .website-container {
                margin: auto;
                color: var(--primary);
                width: max-content;
                box-shadow: 0 0 1px 1px var(--primary);
                padding: 10px 20px;
                border-radius: 2px;
                transition: all 0.3s ease-in-out;

                &:hover {
                    font-weight: 600;
                    color: var(--secondary);
                    background-color: var(--tertiary);
                    box-shadow: 0 0 1px 1px var(--secondary);
                }
            }
        }

        .nav-buttons-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: auto;

            button {
                width: 200px;
                background-color: var(--primary);
                color: var(--secondary);
                border: none;
                padding: 10px 20px;
                border: 1px solid transparent;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                border-radius: 3px;

                &.active {
                    background-color: var(--quaternary);
                    color: var(--primary);
                }

                &.normal:hover {
                    background-color: var(--secondary);
                    border: 1px solid white;
                    color: var(--primary);
                }
            }
        }
    }

    .bottom-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: var(--primary);

        .left-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            gap: 5px;

            .colors-container {
                display: flex;
                gap: 10px;

                div {
                    width: 40px;
                    height: 40px;
                    border-radius: 3px;
                    color: var(--secondary);
                    border: 1px solid var(--primary);
                    transition: all 0.3s ease-in-out;
                }
            }

            h5 {
                font-weight: 600;

                .refresh-text {
                    color: var(--tertiary);
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .right-container {
            width: 100%;
            display: flex;
            justify-content: end;
            align-items: end;
            gap: 20px;

            .social-media-container {
                position: relative;
                width: 40px;
                height: 40px;
                border-radius: 1px;
                box-shadow: 0 0 1px 1px var(--primary);
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: var(--tertiary);

                    svg {
                        color: var(--secondary);
                    }
                }

                a {
                    text-align: center;
                    width: 100%;
                }


                svg {
                    color: var(--primary);
                    transition: all 0.2s ease-in-out;
                    font-size: 25px;
                }
            }

            .download-cv-container {
                position: relative;
                display: flex;
                flex-direction: column;

                .cv-container {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: max-content;
                    top: -70px;
                    display: flex;
                    flex-direction: column;

                    .text {
                        padding: 5px;
                        border-radius: 3px;
                        font-weight: 400;
                        color: var(--primary);
                    }

                    .arrow {
                        align-self: flex-end;
                        font-size: 30px;
                        color: var(--primary) !important;
                    }
                }
            }
        }
    }
}

#background-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: var(--bg-color);
    background: linear-gradient(to bottom right, var(--bg-color) 70%, var(--quaternary));
    overflow: hidden;
}


.primary {
    background-color: var(--primary)
}

.secondary {
    background-color: var(--secondary)
}

.tertiary {
    background-color: var(--tertiary)
}

.quaternary {
    background-color: var(--quaternary)
}

@media only screen and (max-width: 700px) {
    .colors-container div {
        width: 30px !important;
        height: 30px !important;
    }

    .social-media-container {
        width: 30px !important;
        height: 30px !important;
    }
}

@media only screen and (max-width: 400px) {
    .bottom-container {
        flex-direction: column;
        gap: 20px;

        .left-container {
            order: 2 !important;
            align-items: center !important;
            gap: 10px !important;

            h5 {
                text-align: center;
                order: 2 !important;
            }

            br {
                display: none;
            }
        }

        .right-container {
            width: 100% !important;
            justify-content: center !important;
            align-items: center !important;

            .cv-container {
                right: -40px !important;
                top: -40px !important;

                .arrow {
                    transform: rotate(45deg) !important;
                }
            }

        }


    }
    #background-container {
        background: linear-gradient(to bottom, var(--bg-color) 70%, var(--quaternary)) !important;
    }
}