@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

:root {
  --primary: #D9D9D9;
  --secondary: #181818;
  --tertiary: #DEADAD;
  --quaternary: #363636;
  --bg-color: #181818;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: var(--primary) var(--bg-color);
  min-height: fit-content;
}

body {
  background-color: var(--bg-color);
  transition: background-color 0.5s ease-in-out;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 22px
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 15px;
}

p {
  font-size: 17px;
}

.container-size {
  position: absolute;
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 700px) {
  .container-size {
    width: 90%;
  }

  h1 {
    font-size: 25px;
  }

  h3{
    font-size: 17px;
  }
  
  h4 {
    font-size: 15px;
  }

  h5 {
    font-size: 13px;
  }
  
  p {
    font-size: 18px;
  }

}

@media only screen and (max-width: 400px) {
  .container-size {
    width: 99%;
  }

  p {
    font-size: 15px;
  }
}