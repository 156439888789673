.picture {
    padding: 0;
}

.picture-one {
    right: 380px;
    top: 0;
}

.picture-two {
    right: 150px;
    top: 0;
}

.picture-three {
    right: 380px;
    bottom: 0;
}

.picture-four {
    right: 150px;
    bottom: 0;
}

.picture-container {
    position: relative;
    width: 200px;
    height: 300px;
    background-color: var(--bg-color);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .image-holder {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        color: var(--tertiary);
        font-size: 12px;
        text-align: center;
        box-sizing: border-box;
    }


    .close-btn {
        position: absolute;
        top: 5px;
        right: 10px;
        z-index: 5;
        color: var(--tertiary);
        font-size: 15px;
        cursor: pointer;
    }
}

@media only screen and (max-width: 700px) {
    .picture-container {
        width: 200px;
        height: 250px;
    }
}


@media only screen and (max-width: 400px) {
    .picture{
        width: max-content !important;
    }

    .picture-container {
        width: 150px !important;
        height: 200px !important;
    }

    .picture-one {
        left: 0 !important;
    }

    .picture-two {
        right: 0 !important;
    }

    .picture-three {
        left: 0 !important;
    }

    .picture-four {
        right: 0 !important;
    }
}