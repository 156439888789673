#about {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: var(--primary);
    z-index: 1;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-text-container {
        position: relative;
        width: 70%;
        height: max-content;
        padding: 30px;
        margin: auto;
        display: flex;
        flex-direction: column;
        background-color: var(--secondary);
        box-shadow: 0 0 1px 1px var(--primary);
        border-radius: 3px;
        text-align: center;
        z-index: 1;
        gap: 15px;
        max-height: 100%;
        overflow-y: auto;
        box-sizing: border-box;

        h4 {
            margin: auto;
            color: var(--primary);
            font-weight: 300;
        }

        h5 {
            color: var(--tertiary);
            margin: 20px auto 0;
            font-style: italic;
            font-weight: 300;
        }

        p {
            margin: auto;
        }

        .error {
            color: var(--tertiary);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .image {
        width: 120px;
        height: 120px;
        margin: 30px auto 0;
        opacity: 0.65 !important;

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            border: 1px solid var(--primary);
            object-fit: cover;
        }
    }

    .cards-container {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 10%;
        top: 0;
        background-color: var(--bg-color);
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        .card {
            padding: 15px 30px;
            width: max-content;
            height: max-content;
            border-radius: 3px;
            transition: opacity 0.3s ease-in-out;
            box-sizing: border-box;
            display: flex;
            justify-content: start;
            align-items: start;
            flex-direction: column;
            gap: 14px;
            opacity: 0.1;
            border: 1px solid var(--primary);
            background-color: var(--quaternary);
            font-weight: 200;
            color: var(--primary);
            transition: all 0.3s ease-in-out;

            p {
                font-size: 8px;
                color: var(--primary);
            }

            ul {
                list-style-type: disc;

                li {
                    padding-bottom: 5px;
                }
            }

            h5 {
                font-weight: 300;
            }

            &:hover {
                z-index: 2;
                opacity: 1;
                left: 0;
            }

        }
    }

    .color-hover:hover {
        color: var(--tertiary);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .dashed {
        border: 1px dashed var(--primary);
    }

    .dotted {
        text-decoration: underline;
    }
}


@media only screen and (max-width: 700px) {
    #about {
        overflow-y: auto;
        height: max-content;
        flex-direction: column;
        padding: 50px 10px 10px;
        gap: 20px;
        left: 0;
        top: 0;
        transform: none;

        .main-text-container {
            width: 90%;
            max-height: max-content !important;
            height: max-content !important;
        }

        .cards-container {
            left: 0;
            width: 90%;
            position: relative;
            align-items: start;
            gap: 10px;

            .card {
                width: 100%;
                height: max-content;
                padding: 10px;
                z-index: 2;
                opacity: 1;
                left: 0;

                ul {
                    list-style-type: disc;
                }

                li {
                    padding-bottom: 3px;
                }

            }
        }

        li{
            list-style: none;
        }

        .image {
            margin: auto;
        }
    }
}

@media only screen and (max-width: 400px) {
    #about {
        padding: 50px 10px 10px;

        .main-text-container {
            width: 99%;
            padding: 10px;
            gap: 10px;
        }

        .cards-container {
            left: 0;
            width: 99%;
            position: relative;
            align-items: start;
            gap: 10px;
        }

        .card {
            padding: 10px !important;
            gap: 5px !important;
        }

        .image {
            width: 100px;
            height: 100px;
        }
    }
}