#topbar {
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    box-sizing: border-box;
    z-index: 10;

    .hamburger-icon {
        position: fixed;
        left: 10px;
        top: 10px;
        color: var(--primary);
        font-size: 30px;
        cursor: pointer;
        z-index: 10;
    }

    .topbar-buttons-container {
        width: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px;
        gap: 15px;
        box-sizing: border-box;
        z-index: 10;
        background-color: var(--bg-color);
        height: 100%;
        box-shadow: 0 0 1px 1px var(--primary);
        transition: all 0.3s ease-in-out;
        display: flex;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        position: fixed;

        .close-icon {
            right: 10px;
            top: 10px;
            color: var(--primary);
            font-size: 30px;
            cursor: pointer;
            z-index: 10;
        }

        button {
            width: 200px;
            background-color: var(--primary);
            color: var(--secondary);
            border: none;
            padding: 10px 20px;
            border: 1px solid transparent;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            border-radius: 3px;

            &.active {
                background-color: var(--quaternary);
                color: var(--primary);
            }

            &.normal:hover {
                background-color: var(--secondary);
                border: 1px solid white;
                color: var(--primary);
            }
        }

        &.show {
            width: 300px;
            opacity: 1;
            visibility: visible;
        }
    }

}


@media only screen and (max-width: 400px) {
    .show {
        width: 100% !important;
    }
}