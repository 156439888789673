.video {
    padding: 0;
}

.video-one {
    right: 250px;
    top: 0;
}

.video-two {
    right: 0px;
    top: 0;
}

.video-three {
    right: 250px;
    bottom: 0;
}

.video-four {
    right: 0;
    bottom: 0;
}

.video-container {
    position: relative;
    width: 300px;
    height: 500px;
    background-color: var(--bg-color);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .video-holder {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .controls-container {
        position: relative;
        height: 100%;
        width: 80%;
        box-sizing: border-box;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        padding: 10px 0;
        box-sizing: border-box;
        gap: 10px;

        button {
            background-color: var(--secondary);
            border: 1px solid var(--primary);
            color: white;
            padding: 7px 12px;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            &.play {
                &:hover {
                    background-color: var(--quaternary);
                }
            }

            &.pause {
                background-color: var(--tertiary);

                &:hover {
                    background-color: var(--quaternary);
                }
            }
        }

        .progress-bar {
            position: relative;
            width: 100%;
            height: 10px;
            background-color: var(--primary);
            border-radius: 5px;
            border: 1px solid var(--primary);
            cursor: pointer;

            .progress {
                height: 100%;
                background-color: var(--secondary);
                border-radius: 5px;
                transition: width 0.1s;
            }
        }

        .volume-control {
            position: relative;
            width: 100px;
            height: 10px;
            background-color: var(--secondary);
            border-radius: 5px;
            border: 1px solid var(--primary);
            cursor: pointer;

            .volume-level {
                height: 100%;
                background-color: var(--tertiary);
                border-radius: 5px;
                transition: width 0.1s;
            }
        }

    }


    .text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        color: var(--tertiary);
        font-size: 12px;
        text-align: center;
    }

    .no-video-message{
        color: var(--tertiary);
        font-size: 20px;
        width: 100%;
        padding: 30px;
    }


    .close-btn {
        position: absolute;
        top: 5px;
        right: 10px;
        z-index: 5;
        color: var(--tertiary);
        font-size: 15px;
        cursor: pointer;
    }
}


@media only screen and (max-width: 700px) {
    .video-container {
        width: 200px;
        height: 250px;
    }

    .video-holder {
        video {
            width: 200px;
            height: 250px;
        }
    }

    .controls-container {
        gap: 5px !important;
        padding: 5px 0 !important;

        button {
            padding: 5px 10px !important;
            font-size: 12px;
        }

        .progress-bar {
            height: 7px !important;

            .progress {
                height: 7px !important;
            }
        }

        .volume-control {
            height: 7px !important;

            .volume-level {
                height: 7px !important;
            }
        }
    }
}


@media only screen and (max-width: 400px) {
    .video{
        width: max-content !important;
    }

    .vide-container {
        width: 150px !important;
        height: 200px !important;
    }

    .video-one {
        left: 0 !important;
    }

    .video-two {
        right: 0 !important;
    }
}