#project-content {
    position: relative;
    padding: 30px;
    width: 70%;
    margin: auto;

    .projects-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        height: 100%;

        .project {
            position: relative;
            width: 300px;
            height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
            color: var(--secondary);
            border: 6px solid transparent;
            box-shadow: 0 0 1px 1px var(--primary);
            border-radius: 5px;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            background-color: var(--secondary);

            .project-name {
                background-color: var(--secondary);
                width: 100%;
                text-align: center;
                padding: 5px 10px 5px 10px;
                z-index: 2;
                color: var(--primary);
            }

            .project-description {
                text-align: center;
                padding: 5px 10px 2px 10px;
                color: var(--primary);
                z-index: 2;
                font-weight: 400;
                width: 100%;
                background-color: var(--secondary);
                box-sizing: border-box;
            }

            .project-image {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .hover-project {
                position: absolute;
                padding: 5px;
                width: 100%;
                top: 100%;
                opacity: 0;
                height: 100%;
                background-color: var(--secondary);
                transition: all 0.3s ease-in-out;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                scrollbar-width: thin;
                scroll-behavior: smooth;
                scrollbar-color: var(--primary) var(--secondary);
                visibility: hidden;
                overflow: auto;
                z-index: 10;

                .project-button {
                    width: 100%;
                    position: relative;
                    border: 0;
                    background-color: var(--quaternary);
                    color: var(--primary);
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;
                    padding: 2px 10px 2px 10px;
                    border-radius: 2px;

                    span {
                        font-weight: 500;
                        line-break: anywhere;
                    }

                    &:hover {
                        color: var(--secondary);
                        background-color: var(--tertiary);
                    }
                }

                .project-technologies {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 15px;
                    padding-bottom: 20px;

                    .project-tech {
                        position: relative;
                        width: 40px;
                        height: 40px;
                        padding: 10px;
                        border-radius: 5px;
                        overflow: hidden;

                        .bg {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background-color: var(--primary);
                            opacity: 0.6;
                            z-index: 1;
                        }

                        img {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            z-index: 2;
                        }
                    }
                }
            }

            &:hover {
                transform: scale(1.1);

                z-index: 3;

                .hover-project {
                    visibility: visible;
                    opacity: 1;
                    top: 0;
                }

                opacity: 1;
                border: 6px solid var(--tertiary);
            }
        }
    }

}



@media only screen and (max-width: 700px) {
    #project-content {
        width: 90%;

        
        .projects-container {
            gap: 10px;
            
            .project{
                width: 250px;
                height: 200px;
            }
        }
    }
}


@media only screen and (max-width: 600px) {
    #project-content .projects-container .project{
        width: 200px;
        height: 200px;
    }
}

@media only screen and (max-width: 480px) {
    #project-content {
        padding: 50px 50px 30px;
        box-sizing: border-box;
        width: 99%;
    }

    #project-content .projects-container .project{
        width: 100%;
        height: 200px;
    }
}

@media only screen and (max-width: 400px) {
    #project-content {
        padding: 50px 10px 10px;
        width: 99%;
    }
}